
import {
  computed, defineComponent, reactive, toRef, watch,
} from '@vue/composition-api';
import { incrementalSaveRecord } from '../store';

const StepperMap: Record<string | number, number | string> = {
  'Submission Home': 1,
  1: 'Submission Home',

  'Submission Context': 2,
  2: 'Submission Context',

  'Study Form': 3,
  3: 'Study Form',

  'Multiomics Form': 4,
  4: 'Multiomics Form',

  'Environment Package': 5,
  5: 'Environment Package',

  'Submission Sample Editor': 6,
  6: 'Submission Sample Editor',

  // 'Validate And Submit': 5,
  // 5: 'Validate And Submit',
};

export default defineComponent({
  setup(props, { root }) {
    const currentRoute = toRef(reactive(root.$router), 'currentRoute');
    const step = computed(() => StepperMap[currentRoute.value.name || ''] || 0);

    const currentRouteName = computed(() => root.$route.name);

    watch(currentRouteName, () => {
      if (root.$route.params.id) {
        incrementalSaveRecord(root.$route.params.id);
      }
    });

    function gotoStep(newstep: number) {
      const routeName = StepperMap[newstep];
      if (newstep < step.value && typeof routeName === 'string') {
        root.$router.push({ name: routeName });
      }
    }

    return { step, gotoStep, StepperMap };
  },
});
