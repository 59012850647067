
import { defineComponent, ref, watch } from '@vue/composition-api';
import { types } from '@/encoding';
import {
  api, Condition, DatabaseSummaryResponse, entityType,
} from '@/data/api';

import ConditionChips from '@/components/Presentation/ConditionChips.vue';

import MenuContent from '@/components/MenuContent.vue';
import FacetedSearch, { SearchFacet } from '@/components/FacetedSearch.vue';

import {
  stateRefs, removeConditions, setConditions, toggleConditions,
} from '@/store';

/**
 * Sidebar has a fixed list of facets, possibly from different tables.
 */
const FunctionSearchFacets: SearchFacet[] = [
  {
    field: 'id',
    table: 'gene_function',
  },
  /** ENVO */
  {
    field: 'env_broad_scale',
    table: 'biosample',
  },
  {
    field: 'env_local_scale',
    table: 'biosample',
  },
  {
    field: 'env_medium',
    table: 'biosample',
  },
  /** GOLD */
  {
    field: 'gold_classification',
    table: 'biosample',
  },
  /** Biosample */
  {
    field: 'geo_loc_name',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'depth',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'latitude',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'longitude',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'collection_date',
    table: 'biosample',
    group: 'Sample',
  },
  /** Study */
  {
    field: 'principal_investigator_name',
    table: 'study',
    group: 'Study',
  },
  /** Omics Processing */
  {
    field: 'instrument_name',
    table: 'omics_processing',
    group: 'Omics Processing',
  },
  {
    field: 'omics_type',
    table: 'omics_processing',
    group: 'Omics Processing',
  },
  {
    field: 'processing_institution',
    table: 'omics_processing',
    group: 'Omics Processing',
  },
];

export default defineComponent({
  components: {
    ConditionChips,
    FacetedSearch,
    MenuContent,
  },

  props: {
    resultsCount: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const filterText = ref('');
    const textSearchResults = ref([] as Condition[]);
    const dbSummary = ref({} as DatabaseSummaryResponse);
    api.getDatabaseSummary().then((s) => { dbSummary.value = s; });

    function dbSummaryForTable(table: entityType, field: string) {
      if (table in dbSummary.value) {
        return dbSummary.value[table].attributes[field];
      }
      return {};
    }

    async function updateSearch() {
      if (filterText.value.length >= 2) {
        textSearchResults.value = await api.textSearch(filterText.value);
      } else {
        textSearchResults.value = [];
      }
    }
    watch(filterText, updateSearch);

    return {
      filterText,
      textSearchResults,
      setConditions,
      FunctionSearchFacets,
      conditions: stateRefs.conditions,
      dbSummary,
      dbSummaryForTable,
      removeConditions,
      toggleConditions,
      types,
    };
  },
});
