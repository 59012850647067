
import { defineComponent, ref } from '@vue/composition-api';
import Definitions from '@/definitions';
import {
  multiOmicsForm, multiOmicsFormValid, multiOmicsAssociations, templateChoiceDisabled, contextForm, canEditSubmissionMetadata,
} from '../store';
import SubmissionDocsLink from './SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './SubmissionPermissionBanner.vue';

export default defineComponent({
  components: { SubmissionDocsLink, SubmissionPermissionBanner },
  setup() {
    const formRef = ref();

    function reValidate() {
      formRef.value.validate();
    }

    return {
      formRef,
      multiOmicsForm,
      multiOmicsAssociations,
      multiOmicsFormValid,
      Definitions,
      templateChoiceDisabled,
      contextForm,
      /* functions */
      reValidate,
      canEditSubmissionMetadata,
    };
  },
});
