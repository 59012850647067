
import { defineComponent, computed } from '@vue/composition-api';
import { HARMONIZER_TEMPLATES } from '../harmonizerApi';
import {
  templateChoiceDisabled,
  templateList,
  packageName,
  canEditSubmissionMetadata,
} from '../store';
import SubmissionDocsLink from './SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './SubmissionPermissionBanner.vue';

export default defineComponent({
  components: { SubmissionDocsLink, SubmissionPermissionBanner },
  setup() {
    const templateListDisplayNames = computed(() => templateList.value
      .map((templateKey) => HARMONIZER_TEMPLATES[templateKey].displayName)
      .join(' + '));

    return {
      packageName,
      HARMONIZER_TEMPLATES,
      templates: Object.entries(HARMONIZER_TEMPLATES),
      templateListDisplayNames,
      templateChoiceDisabled,
      canEditSubmissionMetadata,
    };
  },
});
