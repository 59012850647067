
import { defineComponent, PropType, ref } from '@vue/composition-api';

import { BaseSearchResult } from '@/data/api';

export default defineComponent({
  props: {
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    titleKey: {
      type: String,
      default: 'name',
    },
    subtitleKey: {
      type: String,
      default: 'description',
    },
    results: {
      type: Array as PropType<BaseSearchResult[]>,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'mdi-book',
    },
    disableNavigateOnClick: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rows = ref(props.itemsPerPage);
    return {
      rows,
    };
  },

});

