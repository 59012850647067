
import { defineComponent } from '@vue/composition-api';
import { writeFile, utils } from 'xlsx';
import { submit, submitPayload } from '../store';
import { saveAs } from '@/util';
import useRequest from '@/use/useRequest';

export default defineComponent({
  setup(_, { root }) {
    // TODO: https://github.com/microbiomedata/nmdc-server/issues/852
    function downloadSamples() {
      const worksheet = utils.aoa_to_sheet([]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      // @ts-ignore
      writeFile(workbook, 'nmdc_sample_export.tsv', { bookType: 'csv', FS: '\t' });
    }

    function downloadJson() {
      saveAs('nmdc_study.json', submitPayload.value);
    }

    const { request, loading: submitLoading, count: submitCount } = useRequest();
    const doSubmit = () => request(() => submit(root.$route.params.id));

    return {
      submitPayload,
      submitLoading,
      submitCount,
      downloadJson,
      downloadSamples,
      doSubmit,
    };
  },
});
