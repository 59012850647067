
import { defineComponent, PropType } from '@vue/composition-api';
import EcosystemSankey from '@/components/EcosystemSankey.vue';

import {
  toggleConditions,
} from '@/store';
import { Condition } from '@/data/api';

export default defineComponent({
  components: {
    EcosystemSankey,
  },

  props: {
    conditions: {
      type: Array as PropType<Condition[]>,
      required: true,
    },
  },

  setup() {
    return {
      toggleConditions,
    };
  },
});
