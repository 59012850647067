
import { computed, defineComponent } from '@vue/composition-api';
import { addressForm } from '../store';
import { addressToString } from '../store/api';

export default defineComponent({
  setup() {
    const shipperAddressOneLiner = computed(() => {
      const shipperData = [
        addressForm.shipper.name,
        addressForm.shipper.line1,
        addressForm.shipper.line2,
        addressForm.shipper.city,
        addressForm.shipper.state,
        addressForm.shipper.postalCode,
      ];
      const existingShipperData = shipperData.filter((shipperDatum) => !!shipperDatum.trim());
      return existingShipperData.join(', ');
    });
    const shipperAddressString = computed(() => addressToString(addressForm.shipper));
    const shipperSummary = computed(() => {
      let result = '';
      result += shipperAddressString.value.trim();
      if (addressForm.shippingConditions) {
        result += `\nShipping Conditions: ${addressForm.shippingConditions}`;
      }
      if (addressForm.expectedShippingDate) {
        const date = new Date(addressForm.expectedShippingDate);
        result += `\nExpected Shipping Date: ${date.toLocaleDateString()}`;
      }

      return result.trim();
    });

    const sampleProperties = computed(() => [
      {
        title: 'Sample Name',
        value: addressForm.sample,
      },
      {
        title: 'Sample Description',
        value: addressForm.description,
      },
      {
        title: 'Experiment Goals',
        value: addressForm.experimentalGoals,
      },
      {
        title: 'Randomization',
        value: addressForm.randomization,
      },
      {
        title: 'USDA Regulated',
        value: addressForm.usdaRegulated,
      },
      {
        title: 'Permit Number',
        value: addressForm.permitNumber,
      },
      {
        title: 'Biosafety Level',
        value: addressForm.biosafetyLevel,
      },
      {
        title: 'IRB/HIPAA Compliance?',
        value: (addressForm.irbOrHipaa ? 'Yes' : 'No'),
      },
    ]);

    return {
      shipperAddressOneLiner,
      shipperSummary,
      addressForm,
      sampleProperties,
    };
  },
});
