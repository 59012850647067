var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h2"},[_vm._v(" Study Information "),_c('submission-docs-link',{attrs:{"anchor":"study"}})],1),_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.NmdcSchema.$defs.Study.description)+" ")]),(!_vm.canEditSubmissionMetadata())?_c('submission-permission-banner'):_vm._e(),_c('v-form',{ref:"formRef",staticClass:"my-6",staticStyle:{"max-width":"1000px"},attrs:{"disabled":!_vm.canEditSubmissionMetadata()},model:{value:(_vm.studyFormValid),callback:function ($$v) {_vm.studyFormValid=$$v},expression:"studyFormValid"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"rules":_vm.requiredRules('Name is required',[
        function (v) { return v.length > 6 || 'Study name too short'; } ]),"validate-on-blur":"","label":"Study Name *","hint":_vm.Definitions.studyName,"persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.studyForm.studyName),callback:function ($$v) {_vm.$set(_vm.studyForm, "studyName", $$v)},expression:"studyForm.studyName"}}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"my-2 mr-4",attrs:{"label":"Principal Investigator Name","hint":_vm.Definitions.piName,"persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.studyForm.piName),callback:function ($$v) {_vm.$set(_vm.studyForm, "piName", $$v)},expression:"studyForm.piName"}}),_c('v-text-field',{staticClass:"my-2",attrs:{"label":"Principal Investigator Email *","rules":_vm.requiredRules('E-mail is required',[
          function (v) { return /.+@.+\..+/.test(v) || 'E-mail must be valid'; } ]),"hint":_vm.Definitions.piEmail,"persistent-hint":"","type":"email","required":"","outlined":"","dense":""},model:{value:(_vm.studyForm.piEmail),callback:function ($$v) {_vm.$set(_vm.studyForm, "piEmail", $$v)},expression:"studyForm.piEmail"}})],1),_c('v-text-field',{staticClass:"my-2",attrs:{"label":"Principal Investigator ORCID","disabled":!_vm.isOwner() || _vm.currentUserOrcid === _vm.studyForm.piOrcid,"outlined":"","hint":_vm.Definitions.piOrcid,"persistent-hint":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.studyForm.piOrcid),callback:function ($$v) {_vm.$set(_vm.studyForm, "piOrcid", $$v)},expression:"studyForm.piOrcid"}}),_c('v-combobox',{staticClass:"my-2",attrs:{"label":"Webpage Links","hint":_vm.Definitions.linkOutWebpage,"persistent-hint":"","outlined":"","dense":"","multiple":"","small-chips":"","clearable":""},model:{value:(_vm.studyForm.linkOutWebpage),callback:function ($$v) {_vm.$set(_vm.studyForm, "linkOutWebpage", $$v)},expression:"studyForm.linkOutWebpage"}}),_c('v-textarea',{staticClass:"my-2",attrs:{"label":"Study Description","hint":_vm.Definitions.studyDescription,"persistent-hint":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.studyForm.description),callback:function ($$v) {_vm.$set(_vm.studyForm, "description", $$v)},expression:"studyForm.description"}}),_c('v-text-field',{staticClass:"my-2",attrs:{"label":"Optional Notes","hint":_vm.Definitions.studyOptionalNotes,"persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.studyForm.notes),callback:function ($$v) {_vm.$set(_vm.studyForm, "notes", $$v)},expression:"studyForm.notes"}}),_c('div',{staticClass:"text-h4"},[_vm._v(" Contributors ")]),_c('div',{staticClass:"text-body-1 mb-2"},[_vm._v(" "+_vm._s(_vm.Definitions.studyContributors)+" ")]),_vm._l((_vm.studyForm.contributors),function(contributor,i){return _c('div',{key:("contributor" + i),staticClass:"d-flex"},[_c('v-card',{staticClass:"d-flex flex-column grow pa-4 mb-4"},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mb-2 mr-3",attrs:{"rules":_vm.requiredRules('Full name is required'),"label":"Full name *","hint":_vm.Definitions.contributorFullName,"outlined":"","dense":"","persistent-hint":""},model:{value:(contributor.name),callback:function ($$v) {_vm.$set(contributor, "name", $$v)},expression:"contributor.name"}}),_c('v-text-field',{style:({ maxWidth: '400px'}),attrs:{"rules":[_vm.orcidRequiredRule(i), _vm.uniqueOrcidRule(i)],"hint":_vm.Definitions.contributorOrcid,"disabled":_vm.currentUserOrcid === contributor.orcid,"label":"ORCID","outlined":"","persistent-hint":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(contributor.orcid),callback:function ($$v) {_vm.$set(contributor, "orcid", $$v)},expression:"contributor.orcid"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"mb-2 mr-3",attrs:{"rules":[function (v) { return v.length >= 1 || 'At least one role is required'; }],"items":_vm.NmdcSchema.$defs.CreditEnum.enum,"label":"CRediT Roles *","hint":_vm.Definitions.contributorRoles,"deletable-chips":"","multiple":"","outlined":"","chips":"","small-chips":"","dense":"","persistent-hint":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(contributor.roles),callback:function ($$v) {_vm.$set(contributor, "roles", $$v)},expression:"contributor.roles"}}),(_vm.isOwner())?_c('v-select',{style:({ maxWidth: '400px'}),attrs:{"items":_vm.permissionLevelChoices,"clearable":"","item-text":"title","item-value":"value","label":"Permission Level","hint":"Level of permissions the contributor has for this submission","outlined":"","dense":"","persistent-hint":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}],null,true)},_vm._l((_vm.permissionHelpText),function(role){return _c('div',{key:role.title,staticClass:"pb-2"},[_c('strong',[_vm._v(_vm._s(role.title)+": ")]),_c('span',[_vm._v(_vm._s(role.description))])])}),0)]},proxy:true}],null,true),model:{value:(contributor.permissionLevel),callback:function ($$v) {_vm.$set(contributor, "permissionLevel", $$v)},expression:"contributor.permissionLevel"}}):_vm._e()],1)]),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.isOwner() || _vm.currentUserOrcid === contributor.orcid},on:{"click":function($event){return _vm.studyForm.contributors.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)],1)}),_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.canEditSubmissionMetadata()},on:{"click":_vm.addContributor}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Add Contributor ")],1)],2),_c('strong',[_vm._v("* indicates required field")]),_c('div',{staticClass:"d-flex mt-5"},[_c('v-btn',{attrs:{"color":"gray","depressed":"","to":{ name: 'Submission Context' }}},[_c('v-icon',{staticClass:"pl-1"},[_vm._v(" mdi-arrow-left-circle ")]),_vm._v(" Go to previous step ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.studyFormValid,"to":{ name: 'Multiomics Form' }}},[_vm._v(" Go to next step "),_c('v-icon',{staticClass:"pl-1"},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }