
import { defineComponent, PropType } from '@vue/composition-api';

import { BaseSearchResult } from '@/data/api';
import { urlify } from '@/data/utils';
import { fieldDisplayName } from '@/util';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<BaseSearchResult>,
      required: true,
    },
    subtitleKey: {
      type: String,
      default: 'description',
    },
  },
  setup() {
    return { fieldDisplayName, urlify };
  },
});
