
import { computed, defineComponent, ref } from '@vue/composition-api';
// @ts-ignore
import Treeselect from '@riophae/vue-treeselect';
import { stateRefs, dataObjectFilter } from '@/store';
import DownloadDialog from '@/components/DownloadDialog.vue';
import useBulkDownload from '@/use/useBulkDownload';
import { humanFileSize } from '@/data/utils';

export default defineComponent({

  components: {
    DownloadDialog,
    Treeselect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    searchResultCount: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const termsDialog = ref(false);

    const {
      loading,
      downloadSummary,
      downloadOptions,
      download,
    } = useBulkDownload(stateRefs.conditions, dataObjectFilter);

    const options = computed(() => Object.entries(downloadOptions.value)
      .map(([key, val]) => ({
        id: key,
        label: `${key} (${val.count})`,
        children: Object.entries(val.file_types).map(([filetype, count]) => ({
          id: `${key}::${filetype}`,
          label: `${filetype} (${count})`,
        })),
      })));

    async function createAndDownload() {
      const val = await download();
      termsDialog.value = false;
      window.location.assign(val.url);
    }

    return {
      bulkDownloadSelected: stateRefs.bulkDownloadSelected,
      options,
      loading,
      downloadSummary,
      termsDialog,
      createAndDownload,
      humanFileSize,
    };
  },
});
