
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc.schema.json';
import {
  addressForm,
  addressFormValid,
  BiosafetyLevels,
  canEditSubmissionMetadata,
} from '../store';
import { addressToString } from '../store/api';
import SubmissionContextShippingSummary from './SubmissionContextShippingSummary.vue';

export default defineComponent({
  components: { SubmissionContextShippingSummary },
  setup() {
    const addressFormRef = ref();
    const showAddressForm = ref(false);
    const datePicker = ref(false);
    const sampleItems = ref(['water_extract_soil']);
    const sampleEnumValues = NmdcSchema.$defs.SampleTypeEnum.enum;
    const biosafetyLevelValues = Object.values(BiosafetyLevels);
    const shippingConditionsItems = [
      'Store frozen: transported within a cold chain and stored at -70°C to -80°C upon delivery.',
      'Store frozen: transported within a cold chain and stored at -20°C (4°F).',
      'Store Refrigerated: at 2°-8°C (36°-46°F): for heat sensitive products that must not be frozen.',
      'Room temperature: Store at 15°-25°C (59°-77°F).',
    ];

    const shipperSummary = computed(() => {
      let result = '';
      result += addressToString(addressForm.shipper);

      return result;
    });

    const addressSummary = computed(() => [{
      id: 'shipper',
      name: 'Shipper',
      children: [
        { id: 'shipperSummary', name: shipperSummary.value },
      ],
    }]);

    const reformatDate = (dateString: string | Date) => new Date(dateString).toISOString().substring(0, 10);

    const expectedShippingDateString = ref('');

    watch(expectedShippingDateString, (newValue: string) => {
      addressForm.expectedShippingDate = newValue.length ? new Date(newValue) : undefined;
    });

    function requiredRules(msg: string, otherRules: ((v: string) => unknown)[]) {
      return [
        (v: string) => !!v || msg,
        ...otherRules,
      ];
    }

    watch(showAddressForm, () => {
      nextTick(() => addressFormRef.value.validate());
    });

    onMounted(() => {
      expectedShippingDateString.value = addressForm.expectedShippingDate
        ? reformatDate(addressForm.expectedShippingDate)
        : '';
    });

    return {
      addressFormRef,
      addressForm,
      addressFormValid,
      showAddressForm,
      datePicker,
      expectedShippingDateString,
      sampleItems,
      biosafetyLevelValues,
      BiosafetyLevels,
      addressSummary,
      sampleEnumValues,
      shippingConditionsItems,
      canEditSubmissionMetadata,
      requiredRules,
    };
  },
});
