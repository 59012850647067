
import { defineComponent } from '@vue/composition-api';
import AuthButton from '@/components/Presentation/AuthButton.vue';
import Menus from '@/menus';

export default defineComponent({
  components: {
    AuthButton,
  },
  setup() {
    return {
      Menus,
    };
  },
});
