
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  nextTick,
} from '@vue/composition-api';
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc.schema.json';
import Definitions from '@/definitions';
import {
  contextForm,
  contextFormValid,
  AwardTypes,
  addressFormValid,
  canEditSubmissionMetadata,
} from '../store';
import SubmissionContextShippingForm from './SubmissionContextShippingForm.vue';
import SubmissionDocsLink from './SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './SubmissionPermissionBanner.vue';

export default defineComponent({
  components: { SubmissionContextShippingForm, SubmissionDocsLink, SubmissionPermissionBanner },
  setup() {
    const formRef = ref();
    const facilityEnum = NmdcSchema.$defs.ProcessingInstitutionEnum.enum.filter(
      (facility: string) => ['EMSL', 'JGI'].includes(facility),
    );
    const projectAwardValidationRules = () => [(v: string) => {
      const awardTypes = Object.values(AwardTypes) as string[];
      const awardChosen = awardTypes.includes(v) || v === contextForm.otherAward;
      const valid = awardChosen || contextForm.facilities.length === 0;
      return valid || 'If submitting to a use facility, this field is required.';
    }];
    const otherAwardValidationRules = () => [(v: string) => {
      const awardTypes = Object.values(AwardTypes) as string[];
      if (contextForm.award && awardTypes.includes(contextForm.award)) {
        return true;
      }
      const inputEmpty = v.trim().length === 0;
      if (contextForm.facilities.length > 0) {
        return !inputEmpty || 'Please enter the kind of project award.';
      }
      return true;
    }];
    const doiRequiredRules = () => [(v: string) => {
      const valid = !!v || !contextForm.facilityGenerated;
      return valid || 'Award DOI associated with DOE user facility project';
    }];
    const revalidate = () => {
      nextTick(() => formRef.value.validate());
    };

    watch(
      () => contextForm.award,
      (award) => {
        const awardTypes = Object.values(AwardTypes) as string[];
        if (award && awardTypes.includes(award)) {
          contextForm.otherAward = '';
        }
        revalidate();
      },
    );

    onMounted(() => {
      formRef.value.validate();
    });

    return {
      Definitions,
      facilityEnum,
      formRef,
      contextForm,
      contextFormValid,
      addressFormValid,
      projectAwardValidationRules,
      otherAwardValidationRules,
      doiRequiredRules,
      revalidate,
      canEditSubmissionMetadata,
    };
  },
});
