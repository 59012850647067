var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","clipped":"","permanent":"","width":"320"}},[_c('div',{staticClass:"mx-3 my-2"},[(_vm.conditions.length)?_c('div',{staticClass:"text-subtitle-2 primary--text d-flex flex-row"},[_c('span',{staticClass:"grow"},[_vm._v("Active query terms")]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.removeConditions}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-off")])],1)]}}],null,false,4013804792)},[_c('span',[_vm._v("Clear query terms")])])],1):_vm._e()]),_c('ConditionChips',{staticClass:"ma-3",attrs:{"conditions":_vm.conditions,"db-summary":_vm.dbSummary},on:{"remove":function($event){return _vm.removeConditions([$event])}},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var field = ref.field;
var table = ref.table;
var isOpen = ref.isOpen;
var toggleMenu = ref.toggleMenu;
return [_c('MenuContent',_vm._b({attrs:{"update":""},on:{"select":function($event){return _vm.setConditions($event.conditions)},"close":function($event){return toggleMenu(false)}}},'MenuContent',{
          field: field,
          table: table,
          isOpen: isOpen,
          conditions: _vm.conditions,
          summary: _vm.dbSummaryForTable(table, field),
        },false))]}}])}),_c('div',{staticClass:"font-weight-bold text-subtitle-2 primary--text mx-3"},[_vm._v(" Found "+_vm._s(_vm.resultsCount)+" results. ")]),_c('v-divider',{staticClass:"my-3"}),_c('FacetedSearch',{attrs:{"filter-text":_vm.filterText,"facet-values":_vm.textSearchResults,"conditions":_vm.conditions,"fields":_vm.FunctionSearchFacets},on:{"update:filterText":function($event){_vm.filterText=$event},"update:filter-text":function($event){_vm.filterText=$event},"select":function($event){return _vm.toggleConditions([$event])}},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
        var field = ref.field;
        var table = ref.table;
        var isOpen = ref.isOpen;
        var toggleMenu = ref.toggleMenu;
return [_c('MenuContent',_vm._b({on:{"select":function($event){return _vm.setConditions($event.conditions)},"close":function($event){return toggleMenu(false)}}},'MenuContent',{
          field: field,
          table: table,
          isOpen: isOpen,
          summary: _vm.dbSummaryForTable(table, field),
          conditions: _vm.conditions,
        },false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }