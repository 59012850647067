
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { StudySearchResults } from '@/data/api';
import OrcidId from './Presentation/OrcidId.vue';

function getOrcid(person: any) {
  const orcid = person?.applies_to_person?.orcid ?? person?.orcid ?? '';
  return orcid.replace('orcid:', '');
}

export default defineComponent({
  components: {
    OrcidId,
  },
  props: {
    item: {
      type: Object as PropType<StudySearchResults>,
      required: true,
    },
  },
  setup(props) {
    const team = computed(() => props.item.has_credit_associations);
    const hasOrcid = computed(() => props.item.principal_investigator?.orcid);
    return {
      team, getOrcid, hasOrcid,
    };
  },
});
