
import { defineComponent } from '@vue/composition-api';
import { stateRefs } from '@/store';

export default defineComponent({
  props: {
    nav: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      me: stateRefs.user,
      orcid: stateRefs.orcid,
    };
  },
});
