
import { defineComponent } from '@vue/composition-api';
import AppHeader from '@/components/Presentation/AppHeader.vue';
import { stateRefs } from '@/store/';

export default defineComponent({
  name: 'App',
  components: { AppHeader },
  setup() {
    return {
      stateRefs,
    };
  },
});
