var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{style:({
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      zIndex: 2,
    }),attrs:{"small":"","color":"primary"},on:{"click":_vm.updateBounds}},[_vm._v(" Search this region ")]),_c('l-map',{ref:"mapRef",style:({
      height: (_vm.height + "px"),
      width: '100%',
      zIndex: 1,
    }),on:{"update:bounds":function($event){_vm.mapProps.bounds = $event}}},[_c('l-tile-layer',{attrs:{"url":_vm.mapProps.url,"attribution":_vm.mapProps.attribution,"options":{
        maxZoom: 22,
        maxNativeZoom: 18,
      }}}),_c('l-marker-cluster',_vm._l((_vm.mapData),function(m,i){return _c('l-marker',{key:i,attrs:{"lat-lng":m.latLng}},[_c('l-popup',[_c('h3',[_vm._v("Sample Collection")]),_c('ul',[_c('li',[_vm._v("Ecosystem: "+_vm._s(m.ecosystem))]),_c('li',[_vm._v("Ecosystem Category: "+_vm._s(m.ecosystem_category))]),_c('li',[_vm._v("Latitude: "+_vm._s(m.latitude))]),_c('li',[_vm._v("Longitude: "+_vm._s(m.longitude))])])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }