var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.conditionGroups),function(group){return _c('v-card',{key:group.key,staticClass:"d-flex flex-row pa-1 my-2",attrs:{"color":"rgb(79, 59, 128, 0.2)"}},[_c('div',{staticStyle:{"width":"94%"}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.fieldDisplayName(group.field, group.table))+" ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" ["+_vm._s(_vm.verb(group.conditions[0].op))+"] ")]),_c('transition-group',{attrs:{"name":"chip"}},_vm._l((group.conditions),function(cond){return _c('v-chip',{key:JSON.stringify(cond.value),staticClass:"ma-1 chip",staticStyle:{"max-width":"90%"},attrs:{"small":"","close":"","label":""},on:{"click:close":function($event){return _vm.$emit('remove', cond)}}},[_c('span',{staticClass:"chip-content"},[_vm._v(" "+_vm._s(_vm.valueTransform(cond.value, cond.field, cond.table))+" ")])])}),1)],1),(group.key !== 'multiomicsbiosample')?_c('v-menu',{attrs:{"offset-x":"","nudge-right":10,"close-on-content-click":false,"value":_vm.menuState[group.key]},on:{"input":function($event){return _vm.toggleMenu(group.key, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"expand d-flex flex-column justify-center",staticStyle:{"width":"6%"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"500"}},[_vm._t("menu",null,null,{
              field: group.field,
              table: group.table,
              isOpen: _vm.menuState[group.key],
              toggleMenu: function (val) { return _vm.toggleMenu(group.key, false); },
            })],2)],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }