
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    href: {
      type: Object as PropType<string | undefined>,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
