var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":5}},[_c('TooltipCard',{attrs:{"text":_vm.helpBarchart}},[_c('FacetSummaryWrapper',{attrs:{"table":"omics_processing","field":"omics_type","conditions":_vm.conditions,"use-all-conditions":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('FacetBarChart',_vm._b({attrs:{"height":360,"show-title":false,"show-baseline":false,"left-margin":120,"right-margin":80},on:{"selected":function($event){return _vm.toggleConditions($event.conditions)}}},'FacetBarChart',props,false))]}}])})],1)],1),_c('v-col',{attrs:{"cols":7}},[_c('TooltipCard',{staticClass:"pa-1",attrs:{"text":_vm.helpMap}},[_c('ClusterMap',{attrs:{"conditions":_vm.conditions,"height":360},on:{"selected":function($event){return _vm.setBoundsFromMap($event)}}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('TooltipCard',{staticClass:"py-2",attrs:{"text":_vm.helpTimeline}},[_c('BinnedSummaryWrapper',{attrs:{"table":"biosample","field":"collection_date","conditions":_vm.conditions,"use-all-conditions":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('DateHistogram',_vm._b({on:{"select":function($event){return _vm.setUniqueCondition(['collection_date'], ['biosample'], $event.conditions)}}},'DateHistogram',props,false))]}}])})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TooltipCard',{staticClass:"py-0 d-flex flex-column justify-center fill-height",attrs:{"text":_vm.helpUpset}},[_c('ChartContainer',{attrs:{"height":160},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
var height = ref.height;
return [_c('UpSet',_vm._b({on:{"select":function($event){return _vm.setUniqueCondition(
                ['omics_type'], ['omics_processing'], $event.conditions)}}},'UpSet',{
                width: width,
                height: height,
                data: _vm.upsetData,
                tooltips: _vm.staticUpsetTooltips,
                order: 'Samples',
              },false))]}}])}),_c('div',{staticClass:"mx-5 upset-legend"},[_c('span',[_vm._v("MG: metagenomics")]),_c('span',[_vm._v("MT: metatranscriptomics")]),_c('span',[_vm._v("MP: metaproteomics")]),_c('span',[_vm._v("MB: metabolomics")]),_c('span',[_vm._v("NOM: natural organic matter")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }