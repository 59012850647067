
import {
  defineComponent,
} from '@vue/composition-api';

export default defineComponent({
  components: {},
  setup() {
    return {};
  },
});
