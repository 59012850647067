
import {
  defineComponent, reactive, computed, ref, Ref,

} from '@vue/composition-api';

import SearchResults from '@/components/Presentation/SearchResults.vue';
import { types } from '@/encoding';
import { fieldDisplayName } from '@/util';
import { api, Condition, StudySearchResults } from '@/data/api';

import {
  stateRefs, toggleConditions, dataObjectFilter,
} from '@/store';
import useFacetSummaryData from '@/use/useFacetSummaryData';
import usePaginatedResults from '@/use/usePaginatedResults';
import useClockGate from '@/use/useClockGate';
import SampleListExpansion from '@/components/SampleListExpansion.vue';

import BulkDownload from '@/components/BulkDownload.vue';
import EnvironmentVisGroup from './EnvironmentVisGroup.vue';
import BiosampleVisGroup from './BiosampleVisGroup.vue';
import SearchSidebar from './SearchSidebar.vue';
import SearchHelpMenu from './SearchHelpMenu.vue';

export default defineComponent({
  name: 'SearchLayout',

  components: {
    BiosampleVisGroup,
    BulkDownload,
    EnvironmentVisGroup,
    SampleListExpansion,
    SearchResults,
    SearchSidebar,
    SearchHelpMenu,
  },

  setup() {
    const showConsortia = ref(true);
    const showStudies = ref(true);
    /**
     * Study checkbox state logic
     */
    const studyCheckboxState = computed(() => (
      stateRefs.conditions.value
        .filter((c) => c.table === 'study' && c.field === 'study_id')
        .map((c) => c.value)
    ));
    function setChecked(studyId: string, { children = [] as StudySearchResults[], omicsType = '' } = {}) {
      const conditions: Condition[] = [{
        value: studyId,
        table: 'study',
        op: '==',
        field: 'study_id',
      }];
      if (omicsType) {
        conditions.push({
          value: omicsType,
          table: 'omics_processing',
          field: 'omics_type',
          op: '==',
        });
      }
      if (children.length > 0) {
        children.forEach((child) => {
          if (!studyCheckboxState.value.includes(child.id)) {
            conditions.push({
              value: child.id,
              table: 'study',
              field: 'study_id',
              op: '==',
            });
          }
        });
      }

      toggleConditions(conditions);
    }

    const studyConditions: Ref<Record<string, Condition[]>> = ref<Record<string, Condition[]>>({
      study: [{
        field: 'study_category',
        table: 'study',
        op: '==',
        value: 'research_study',
      }],
      consortia: [{
        field: 'study_category',
        table: 'study',
        op: '==',
        value: 'consortium',
      }],
    });

    /**
     * SearchLayout-level settings
     */
    const disableBulkDownload = ref(true);
    api.getAppSettings().then((appSettings) => {
      disableBulkDownload.value = appSettings.disable_bulk_download;
    });

    /**
     * Expanded Omics details
     */
    const expandedOmicsDetails = reactive({
      resultId: '',
      omicsProcessingId: '',
    });
    function setExpanded(resultId: string, omicsProcessingId: string) {
      if (expandedOmicsDetails.resultId !== resultId
        || expandedOmicsDetails.omicsProcessingId !== omicsProcessingId) {
        expandedOmicsDetails.resultId = resultId;
        expandedOmicsDetails.omicsProcessingId = omicsProcessingId;
      } else {
        expandedOmicsDetails.resultId = '';
        expandedOmicsDetails.omicsProcessingId = '';
      }
    }

    const biosampleType = types.biosample;
    const biosample = usePaginatedResults(stateRefs.conditions, api.searchBiosample, dataObjectFilter);

    const studyType = types.study;
    const studySummaryData = useFacetSummaryData({
      field: ref('study_id'),
      table: ref('study'),
      conditions: stateRefs.conditions,
    });
    const studyCondition = computed(() => studyConditions.value.study.concat(studySummaryData.otherConditions.value));
    const consortiumCondition = computed(() => studyConditions.value.consortia.concat(studySummaryData.otherConditions.value));

    const study = usePaginatedResults(ref(studyCondition), api.searchStudy, undefined, 5);
    const consortium = usePaginatedResults(ref(consortiumCondition), api.searchStudy, undefined, 5);

    const studyResults = computed(() => Object.values(study.data.results.results)
      .map((r) => ({
        ...r,
        name: r.annotations.title || r.name,
        children: r.children?.map((c) => ({
          ...c,
          name: c.annotations.title || c.name,
        })),
      })));
    const consortiumStudyResults = computed(() => Object.values(consortium.data.results.results)
      .map((r) => ({
        ...r,
        name: r.annotations.title || r.name,
        children: r.children?.map((c) => ({
          ...c,
          name: c.annotations.title || c.name,
        })),
      })));

    const loggedInUser = computed(() => typeof stateRefs.user.value === 'string');

    const vistab = ref(0);
    const gatedOmicsVisConditions = useClockGate(
      computed(() => (vistab.value === 0)),
      stateRefs.conditions,
    );
    const gatedEnvironmentVisConditions = useClockGate(
      computed(() => (vistab.value === 1)),
      stateRefs.conditions,
    );
    const showChildren:Ref<any[]> = ref([]);
    function toggleChildren(value:StudySearchResults) {
      // eslint-disable-next-line no-unused-expressions
      showChildren.value.includes(value.id) ? showChildren.value.splice(showChildren.value.indexOf(value.id), 1) : showChildren.value.push(value.id);
    }

    return {
      /* data */
      biosampleType,
      biosample,
      dataObjectFilter,
      expandedOmicsDetails,
      gatedEnvironmentVisConditions,
      gatedOmicsVisConditions,
      loggedInUser,
      disableBulkDownload,
      studyType,
      study,
      consortium,
      studyResults,
      consortiumStudyResults,
      showConsortia,
      showStudies,
      showChildren,
      studyCheckboxState,
      types,
      vistab,
      toggleChildren,
      /* methods */
      setChecked,
      setExpanded,
      fieldDisplayName,
    };
  },
});
