
import { defineComponent, ref, watchEffect } from '@vue/composition-api';
import { api, BiosampleSearchResult } from '@/data/api';
import AttributeList from '@/components/Presentation/AttributeList.vue';

import IndividualTitle from './IndividualTitle.vue';

export default defineComponent({
  name: 'SamplePage',

  components: {
    AttributeList,
    IndividualTitle,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const result = ref({} as BiosampleSearchResult);

    watchEffect(() => {
      api.getBiosample(props.id).then((b) => { result.value = b; });
    });

    return { result };
  },
});
